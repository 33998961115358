import { useState, useEffect } from "react";
import {
  ComposedModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FilterableMultiSelect,
  Stack,
  Form,
  NumberInput,
  Select,
  SelectItem,
  Checkbox,
} from "@carbon/react";
import config from "../../config";
import { fetchGuides, fetchShift, updateShift } from "./ManageGuidesModalUtils";
import { useRefreshBookings } from "../../helpers/refreshBookings";
export function ManageGuidesModal({
  user,
  tour,
  time,
  open,
  setOpen,
  company,
  date,
  shift,
  setShift,
  bookings,
  setBookings,
}) {
  const [guides, setGuides] = useState([]);
  const [selectedGuides, setSelectedGuides] = useState([]);
  const refreshBookings = useRefreshBookings(date, time, tour, setBookings);

  useEffect(() => {
    fetchGuides(user, setGuides);
  }, [user]);

  useEffect(() => {
    fetchShift(time, date, tour, company, shift, setShift);
  }, [time, date, tour, company]);

  useEffect(() => {
    if (shift && shift.guides && shift.guides.length > 0) {
      const guideIds = shift.guides.map((guide) => guide._id);
      const uniqueGuideIds = [...new Set(guideIds)];
      setSelectedGuides(uniqueGuideIds);
    }
  }, [shift]);
  const handleGuidesChange = (selectedItemsObject) => {
    const selectedItemsArray = selectedItemsObject.selectedItems || [];
    //Timeout used to finish rendering MultiSelect and rerender Modal
    setTimeout(() => {
      setSelectedGuides(selectedItemsArray.map((item) => item._id));
    }, 0);
  };
  useEffect(() => {
    if (shift) {
    }

    if (open && shift) {
      const updateShift = async () => {
        const response = await fetch(
          `${config.apiUrl}/api/shift/${encodeURIComponent(shift.shiftString)}`
        );
        if (!response.ok) {
        } else {
          const updatedShift = await response.json();
          if (JSON.stringify(updatedShift) !== JSON.stringify(shift)) {
            setShift(updatedShift); // Assuming you have a state setter function called setShift
          }
        }
      };

      updateShift();
    }
  }, [open]);
  // console.log("selectedGuides", selectedGuides);

  const handleSubmit = async (e) => {
    await updateShift(
      shift,
      assignedAttendees,
      guides,
      selectedGuides,
      user,
      setShift
    );
    setOpen(false);
  };

  // console.log("shift guides", shift && shift.guides);

  // console.log("guides:", guides);

  // You may need to fill in the details for bookings based on your requirements
  const selectedGuidesObjects = selectedGuides.map((guideId) =>
    guides.find((guide) => guide._id === guideId)
  );

  const [assignedAttendees, setAssignedAttendees] = useState({});
  // console.log("assignedAttendees", assignedAttendees);
  // Update assignedAttendees in the onChange handler
  const handleNumberInputChange = (guideId, key, value) => {
    setAssignedAttendees((prevState) => ({
      ...prevState,
      [guideId]: {
        ...prevState[guideId],
        [key]: Number(value),
      },
    }));
  };

  // Initialize assignedAttendees when selectedGuides or shift changes
  useEffect(() => {
    const hasBookingsWithGuide = bookings.some(
      (booking) => booking.guide != null
    );

    if (hasBookingsWithGuide) {
      const initialAssignedAttendees = {};
      setAssignedAttendees(initialAssignedAttendees);
    }

    if (shift && shift.totalAttendees && selectedGuides.length > 0) {
      const initialAssignedAttendees = {};

      if (hasBookingsWithGuide) {
        shift.guides.forEach((guide) => {
          const guideId = guide._id;
          guide.attendees.forEach((attendee) => {
            const key = Object.keys(attendee)[0]; // This will be "Adults" or "Children"
            const value = attendee[key];

            if (!initialAssignedAttendees[guideId]) {
              initialAssignedAttendees[guideId] = {};
            }

            initialAssignedAttendees[guideId][key] = value;
          });
        });
      } else {
        shift.totalAttendees.forEach((totalAttendee) => {
          const key = Object.keys(totalAttendee)[0]; // This will be "Adults" or "Children"
          const totalValue = totalAttendee[key];
          const averageValue = Math.floor(totalValue / selectedGuides.length);
          const remainder = totalValue % selectedGuides.length;

          selectedGuides.forEach((guideId, index) => {
            const valueForThisGuide =
              averageValue + (index < remainder ? 1 : 0);

            if (!initialAssignedAttendees[guideId]) {
              initialAssignedAttendees[guideId] = {};
            }

            initialAssignedAttendees[guideId][key] = valueForThisGuide;
          });
        });
      }
      setAssignedAttendees(initialAssignedAttendees);
    }
  }, [selectedGuides, shift]);

  const [differenceByType, setDifferenceByType] = useState({});

  // Calculate differenceByType when assignedAttendees changes
  const calculateTotalAttendeesByType = (totalAttendees) => {
    const totalAttendeesByType = {};
    totalAttendees.forEach((totalAttendee) => {
      const key = Object.keys(totalAttendee)[0];
      const totalValue = totalAttendee[key];
      totalAttendeesByType[key] = (totalAttendeesByType[key] || 0) + totalValue;
    });
    return totalAttendeesByType;
  };

  const calculateAssignedAttendeesByType = (assignedAttendees) => {
    const assignedAttendeesByType = {};
    Object.values(assignedAttendees).forEach((assignedAttendee) => {
      Object.entries(assignedAttendee).forEach(([key, value]) => {
        assignedAttendeesByType[key] =
          (assignedAttendeesByType[key] || 0) + value;
      });
    });
    return assignedAttendeesByType;
  };

  const calculateDifferenceByType = (
    assignedAttendeesByType,
    totalAttendeesByType
  ) => {
    const differenceByType = {};
    Object.keys(totalAttendeesByType).forEach((type) => {
      differenceByType[type] =
        (assignedAttendeesByType[type] || 0) -
        (totalAttendeesByType[type] || 0);
    });
    return differenceByType;
  };

  useEffect(() => {
    if (shift && shift.totalAttendees) {
      const totalAttendeesByType = calculateTotalAttendeesByType(
        shift.totalAttendees
      );
      const assignedAttendeesByType =
        calculateAssignedAttendeesByType(assignedAttendees);
      const differenceByType = calculateDifferenceByType(
        assignedAttendeesByType,
        totalAttendeesByType
      );
      setDifferenceByType(differenceByType);
    }
  }, [assignedAttendees, shift, selectedGuides]);

  const getTotalAttendees = (attendees) => {
    return attendees.reduce((acc, attendee, index, array) => {
      const key = Object.keys(attendee)[0];
      const value = attendee[key];
      const isLast = index === array.length - 1;
      return acc + `${value} ${key}${isLast ? "" : ", "}`;
    }, "");
  };

  const AttendeesInfo = ({ shift, selectedGuides, differenceByType }) => {
    if (!shift || !shift.totalAttendees || selectedGuides.length < 2) {
      return null;
    }

    return (
      <div className="attendees-info-guides-modal">
        <p>Total: {getTotalAttendees(shift.totalAttendees)}</p>
        {Object.entries(differenceByType).map(([type, count]) =>
          count !== 0 ? (
            <p key={type}>
              There are{" "}
              <strong>
                {count} extra {type}
              </strong>{" "}
              assigned
            </p>
          ) : null
        )}
      </div>
    );
  };

  const NumberInputComponent = ({
    guideId,
    shift,
    selectedGuides,
    assignedAttendees,
    handleNumberInputChange,
  }) => {
    return shift.totalAttendees.map((totalAttendee) => {
      const key = Object.keys(totalAttendee)[0];
      const isDisabled = selectedGuides.length === 1;

      return (
        <NumberInput
          key={`${guideId}-${key}`}
          id={`${guideId}-number-input`}
          label={key}
          min={0}
          value={assignedAttendees[guideId][key] || 0}
          disabled={isDisabled}
          onChange={(event, { value }) =>
            handleNumberInputChange(guideId, key, value)
          }
        />
      );
    });
  };
  const CheckboxComponent = ({ bookingsForGuide, handleCheckboxChange }) => {
    return bookingsForGuide.length > 0 ? (
      <>
        {bookingsForGuide.map((booking) => (
          <div key={booking._id}>
            <Checkbox
              id={`input-${booking._id}`} // Different from key
              labelText={
                `${booking.name}, ` +
                booking.attendees
                  .reduce((acc, attendee, index) => {
                    const entries = Object.entries(attendee)
                      .filter(([key, value]) => value !== 0) // Remove entries with a value of 0
                      .map(([key, value]) => `${value} ${key.substring(0, 3)}`); // Map entries to strings
                    return acc.concat(entries);
                  }, [])
                  .join(", ") +
                (user.company.displaySourcesModal === true
                  ? `, ${booking.source}`
                  : "")
              }
              name={booking.name}
              onChange={handleCheckboxChange}
              checked={selectedCheckboxes.includes(booking._id)}
            />
          </div>
        ))}
      </>
    ) : (
      <p>No bookings associated</p>
    );
  };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  // Check if there are any bookings where booking.guide is not null
  const hasBookingsWithGuide = bookings.some(
    (booking) => booking.guide != null
  );

  const CheckboxGuide = ({
    guideId,
    guides,
    bookings,
    selectedCheckboxes,
    setSelectedCheckboxes,
  }) => {
    let guide;
    let bookingsForGuide;

    if (guideId === "Unassigned") {
      guide = { name: user.name };
      bookingsForGuide = bookings.filter((booking) => {
        return (
          booking.guide === null &&
          booking.attendees.some((attendee) => {
            return Object.values(attendee).some((count) => count > 0);
          })
        );
      });
    } else {
      guide = guides.find((guide) => guide._id === guideId);
      if (!guide) return null;
      bookingsForGuide = bookings.filter(
        (booking) => booking.guide === guide.name
      );
    }

    const handleCheckboxChange = (event) => {
      const id = event.target.id.replace("input-", ""); // remove 'input-' from the id
      if (event.target.checked) {
        setSelectedCheckboxes([...selectedCheckboxes, id]);
      } else {
        setSelectedCheckboxes(
          selectedCheckboxes.filter((checkboxId) => checkboxId !== id)
        );
      }
    };

    return (
      <div key={guideId}>
        <h4 className="margin-button-4">{guide.name}</h4>
        <CheckboxComponent
          bookingsForGuide={bookingsForGuide}
          handleCheckboxChange={handleCheckboxChange}
          selectedCheckboxes={selectedCheckboxes}
        />
      </div>
    );
  };
  const hasUnassignedBookings = bookings.some((booking) => {
    if (booking.guide === null) {
      let totalAttendees = 0;
      booking.attendees.forEach((attendeeGroup) => {
        Object.values(attendeeGroup).forEach((num) => {
          totalAttendees += num;
        });
      });
      return totalAttendees > 0;
    }
    return false;
  });
  const NumberInputGuide = ({
    guideId,
    guides,
    shift,
    selectedGuides,
    assignedAttendees,
    handleNumberInputChange,
  }) => {
    const guide = guides.find((guide) => guide._id === guideId);
    if (
      !guide ||
      !shift ||
      !shift.totalAttendees ||
      !assignedAttendees[guideId]
    ) {
      return null;
    }

    return (
      <div key={guideId}>
        <h4 className="margin-button-4">{guide.name}</h4>
        <NumberInputComponent
          guideId={guideId}
          shift={shift}
          selectedGuides={selectedGuides}
          assignedAttendees={assignedAttendees}
          handleNumberInputChange={handleNumberInputChange}
        />
      </div>
    );
  };
  // console.log("hasbookingswithguide", hasBookingsWithGuide);
  const [guideToAssign, setGuideToAssign] = useState(null);
  // console.log("guideToAssign", guideToAssign);
  const updateAssignedAttendees = async () => {
    // console.log("RUNNING UPDATEASSIGNEDATTENDEES");
    const latestBookings = await refreshBookings();

    // console.log("latest bookings", latestBookings);
    await refreshBookings();

    const guideNameToId = {};
    for (let guide of guides) {
      guideNameToId[guide.name] = guide._id;
    }

    // Initialize an empty object for the new assigned attendees
    let newAssignedAttendees = {};

    // Iterate over the bookings
    for (let booking of latestBookings) {
      // Check if the booking has a guide and the guide's name exists in the mapping
      if (booking.guide && guideNameToId[booking.guide]) {
        // Get the guide's ID from the mapping
        const guideId = guideNameToId[booking.guide];

        // Check if the guide's ID exists in newAssignedAttendees
        if (!newAssignedAttendees[guideId]) {
          // If not, initialize it with an empty object
          newAssignedAttendees[guideId] = {};
        }

        // Iterate over the booking's attendees
        for (let attendee of booking.attendees) {
          // Add the attendee's values to the existing values
          for (let key in attendee) {
            if (newAssignedAttendees[guideId][key]) {
              newAssignedAttendees[guideId][key] += attendee[key];
            } else {
              newAssignedAttendees[guideId][key] = attendee[key];
            }
          }
        }
      }
    }
    // console.log("newAssignedAttendees", newAssignedAttendees);
    // Update the assignedAttendees state
    setAssignedAttendees(newAssignedAttendees);
    return newAssignedAttendees;
  };

  const assignGuideManually = async (selectedCheckboxes) => {
    // Fetch the guide details from the server
    const guide = guides.find((guide) => guide._id === guideToAssign);

    // Make a single request to your server to update all the bookings
    await fetch(`${config.apiUrl}/api/bookings/assign-guide`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        bookingIds: selectedCheckboxes,
        guide: guide.name,
      }),
    });
    await refreshBookings();
    const newAssignedAttendees = await updateAssignedAttendees();
    updateShift(
      shift,
      newAssignedAttendees,
      guides,
      selectedGuides,
      user,
      setShift
    );
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        if (hasBookingsWithGuide) {
          if (selectedCheckboxes.length > 0) {
            assignGuideManually(selectedCheckboxes);
          } else {
            updateShift(
              shift,
              assignedAttendees,
              guides,
              selectedGuides,
              user,
              setShift
            );
          }
          setOpen(false);
        } else {
          handleSubmit(e);
        }
      }}
    >
      <ComposedModal open={open} onClose={() => setOpen(false)}>
        <ModalHeader title="Manage Guides" />
        <ModalBody>
          <Stack className="add-bookings-modal-stack" gap={8}>
            <FilterableMultiSelect
              id="selectGuides"
              titleText="Select Guides"
              label="Guides"
              items={guides}
              itemToString={(item) => (item ? item.name : "")}
              onChange={handleGuidesChange}
              // selectedItems={selectedGuidesObjects}
              initialSelectedItems={selectedGuidesObjects}
              // A random key enforces the remounting of the component every time the parent is rendered
              key={Math.random()}
            />
            <>
              <AttendeesInfo
                shift={shift}
                selectedGuides={selectedGuides}
                differenceByType={differenceByType}
              />
              {selectedCheckboxes.length > 0 && (
                <Select
                  id="assignGuideManually"
                  labelText="Assign"
                  onChange={(event) => setGuideToAssign(event.target.value)}
                >
                  <SelectItem value="" text="Assign bookings to" />
                  {selectedGuides.map((guideId) => {
                    // Find the guide object that matches the guideId
                    const guide = guides.find((guide) => guide._id === guideId);

                    return (
                      <SelectItem
                        key={guideId}
                        value={guideId}
                        text={guide ? guide.name : ""}
                      />
                    );
                  })}
                </Select>
              )}

              {shift &&
                shift.totalAttendees &&
                selectedGuides &&
                selectedGuides.map((guideId, index) => {
                  return hasBookingsWithGuide ? (
                    <CheckboxGuide
                      key={index}
                      guideId={guideId}
                      guides={guides}
                      bookings={bookings}
                      selectedCheckboxes={selectedCheckboxes}
                      setSelectedCheckboxes={setSelectedCheckboxes}
                    />
                  ) : (
                    <NumberInputGuide
                      key={index}
                      guideId={guideId}
                      guides={guides}
                      shift={shift}
                      selectedGuides={selectedGuides}
                      assignedAttendees={assignedAttendees}
                      handleNumberInputChange={handleNumberInputChange}
                    />
                  );
                })}

              {hasBookingsWithGuide && hasUnassignedBookings && (
                <CheckboxGuide
                  key="Unassigned"
                  guideId="Unassigned"
                  guides={guides}
                  bookings={bookings}
                  selectedCheckboxes={selectedCheckboxes}
                  setSelectedCheckboxes={setSelectedCheckboxes}
                />
              )}
            </>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button kind="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            type="submit"
            // disabled={Object.values(differenceByType).some(
            //   (count) => count !== 0
            // )}
          >
            Save Guides
          </Button>
        </ModalFooter>
      </ComposedModal>
    </Form>
  );
}
